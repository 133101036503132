
h1 {
  text-align: center;
}

.container {
  width: 100%;
  margin: auto;
}

.grid td {
  width: 100px;
  height: 100px;
  border: 2px solid orange;
  text-align: center;
  float: left;
  margin: 5px;
  margin-left: 15px;
}

.grid td:hover {
  opacity: 0.7;
  cursor: pointer;
}

.reserved {
  background: orange;
  color: #fff;
  display: inline-block;
}

.selected {
  background: red;
  color: #fff;
  display: inline-block;
}

.reserved::after {
  content: "R";
}

.selected::after {
  content: "S";
}

.available::after {
  content: "A";
}

.available {
  color: #333;
}

li {
  list-style: none;
}

.btnmargin {
  margin-top: 15px;
}
